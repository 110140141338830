<script setup lang="ts">
interface Question {
  question: string
  answer: string
  md?: boolean
}

const props = defineProps({
  questions: {
    type: Array as PropType<Question[]>,
    default: () => [],
  },
})

const isExpanded = ref(new Map())

useSchemaOrg([
  defineWebPage({
    '@type': 'FAQPage',
  }),
  ...props.questions.map(q => defineQuestion({
    name: q.question,
    acceptedAnswer: q.answer,
  })),
])
</script>

<template>
  <div class="relative rounded-md bg-white shadow-amalo">
    <dl class="space-y-6 divide-y divide-primary-300 ">
      <template v-for="FAQ in questions" :key="FAQ.question">
        <dt class="flex pt-6">
          <button
            type="button"
            class="flex items-center font-regola-medium text-left"
            @click="isExpanded.set(FAQ, !isExpanded.get(FAQ))"
          >
            <Icon v-show="!isExpanded.get(FAQ)" name="PlusSquircleIcon" class="mr-4 text-xl text-primary-500" />
            <Icon v-show="isExpanded.get(FAQ)" name="MinusSquircleIcon" class="mr-4 text-xl text-primary-500" />
            {{ FAQ.question }}
          </button>
        </dt>
        <dd v-show="isExpanded.get(FAQ)" class=" mt-4">
          <Marked v-if="FAQ.md" :text="FAQ.answer" class="text-base ml-4 text-primary-900/75 prose max-w-full pt-4" />
          <div v-else class="text-base ml-4 text-primary-900/75 prose max-w-full pt-4">
            {{ FAQ.answer }}
          </div>
        </dd>
      </template>
    </dl>
  </div>
</template>
